<template>
  <div v-if="info != null" :key="info.authentication_method" v-bind:class="{ '': notSending }" v-show="info.authentication_method != 'donotsend'" class="NotRegistered">
    <div class="d-flex" :key="options.length">
      <div class="flex-grow-1">
        <div  class="form-control-plaintext" :title="info.email" v-b-tooltip.hover>
          <i class="fal fa-trash-alt clickable mr-2" @click="removeBtn"></i> {{displayEmail}}
        </div>
      </div>
      <div>
        <b-form inline>
          <b-form-select v-model="info.language" class="w-150 choose-lang"> 
            <b-form-select-option
              v-for="(value, key) in locales"
              :key="key"
              :value="key"
            >
              {{ $t("LANGUAGE." + key) }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            class="no-mobile-padding-left"
            v-model="info.authentication_method"
            :options="options"
            :state="validated" 
          ></b-form-select>
            <div class="mobile-block">
            <Phone
              @changed="changedData"
              @valid="setValidated"
              :value="info.authentication_identifier"
              v-if="info.authentication_method == 'sms'"
            ></Phone>
            <Pnr
              @changed="changedData"
              @valid="setValidated"
              :value="info.authentication_identifier"
              v-if="info.authentication_method == 'bankid-se'"
            ></Pnr>
            <Pnr
              @changed="changedData"
              @valid="setValidated"
              :value="info.authentication_identifier"
              v-if="info.authentication_method == 'se-eid'"
            ></Pnr>
            <Pnr
              @changed="changedData"
              @valid="setValidated"
              :value="info.authentication_identifier"
              v-if="info.authentication_method == 'freja'"
            ></Pnr>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Phone: () => import("@/components/Compose/Phone"),
    Pnr: () => import("@/components/Compose/Pnr")
  },
  props: ["item", "index", "methods","loa_level"],
  data() {
    return {
      validated: true,
      info: this.item,
      phone: "",
      fetch_country: true,
      options: []
    };
  },
  methods: {
    removeBtn() {
      let self = this;      
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.info.authentication_method = "donotsend";
        }
      })
      .catch(function(){
      }); 
    },
    init() {
      if(this.info.authentication_method != 'conversation')
      {
        for(let ix = 0; ix < this.methods.length; ix++)
        {
          let method = this.methods[ix];
          if(method.loa_level >= this.loa_level)
          {
            this.options.push({ value: method.name, text: this.$t('AUTH_METHODS.' + method.name) });
          }
        }
      }
    },
    setValidated(value) {
      this.validated = value;
      this.$emit("setValidated", { index: this.index, value: value });
    },
    setMethod(key) {
      this.info.authentication_method = key;
    },
    changedData(result) {
      this.info.authentication_identifier = result.value;
    },
  },
  watch: {
    "info.authentication_method": function(newVal) {
      this.info.authentication_identifier = "";
      if (newVal == "donotsend") {
        this.setValidated(true);
      }
      if (newVal == "link") {
        this.setValidated(true);
      }
      if (newVal == "sms") {
        this.setValidated(null);
      }
      if( (newVal == "se-eid") 
      ||  (newVal == "bankid-se") 
      ||  (newVal == "freja") 
      )  {
        this.setValidated(null);
      }
      if (newVal == "siths") {
        this.setValidated(true);
      }
      if (newVal == "siths-card") {
        this.setValidated(true);
      }
      if (newVal == "sefos-certificate") {
        this.setValidated(true);
      }
    },
  },
  computed: {
    displayEmail(){
      let max = 60;
      if(this.info.email.length > max)
      {
        return this.info.email.substring(0,max) + "..";
      }else{
        return this.info.email;
      }
    },
    notSending() {
      return this.info.authentication_method == "donotsend";
    },
  },
  mounted() {
    if (this.info.authentication_method == "donotsend") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "link") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "sms") {
      this.setValidated(null);
    }
     if ( (this.info.authentication_method == "freja") 
      || (this.info.authentication_method == "bankid-se")
      || (this.info.authentication_method == "se-eid") 
      ) {
      this.setValidated(null);
    }
    if (this.info.authentication_method == "siths") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "sefos-certificate") {
      this.setValidated(true);
    }
    this.init();
  },
};
</script>
<style></style>
